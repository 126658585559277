.custom-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.spinner {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    background-color: rgb(214, 170, 10); /* Yellow circular background */
    border-radius: 50%; /* Makes the background a circle */
    box-shadow: 0 0 15px rgba(19, 18, 9, 0.8); /* Optional glow effect */
}

.spinner img {
    width: 60px;
    height: 60px;
    animation: blink 4s infinite;
}

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
}
