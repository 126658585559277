/* .animate-slide-left {
    animation: slide-left 20s linear infinite;
    }
    
    @keyframes slide-left {
    0% {
    transform: translateX(0);
    }
    100% {
    transform: translateX(-100%);
    }
  } */


.slick-slider {
        height: 100vh;
      }
      


@media (min-width: 768px) {
        .slick-slide {
          height: 100%;
        }
        .slick-slide img {
          height: 100%;
          object-fit: cover;
        }
      }
      
    