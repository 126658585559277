@tailwind base;
@tailwind components;
@tailwind utilities;
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=UnifrakturMaguntia&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Limelight&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Limelight&family=Send+Flowers&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Limelight&family=Mystery+Quest&family=Send+Flowers&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Limelight&family=Mystery+Quest&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Send+Flowers&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');





